<template>
  <div class="resource">
    <van-nav-bar
      title="资源跟踪"
      left-arrow
      :border="false"
      @click-left="onClickLeft"
    >
    </van-nav-bar>
    <div class="rescuecon">
      <van-tabs v-model="active" :ellipsis="false" @change="changeTab">
        <van-tab v-for="(item, index) in tabList" :key="index">
          <template #title>
            {{ item.label }}
            <div :class="item.isRead ? 'noread' : ''"></div>
          </template>
        </van-tab>
      </van-tabs>
      <div class="rescuebody">
        <list :type="active" @setReadStatus="setReadStatusFun" />
      </div>
    </div>
  </div>
</template>

<script>
import list from "./components/list.vue";
import api from "./api";
import { mapState, mapMutations } from "vuex";
export default {
  name: "Resource",
  components: { list },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo,
      activeResource: state => state.accidentReportStore.activeResource
    })
  },
  data() {
    return {
      active: 0,
      tabList: [
        { label: "全部（0）", value: 0, isRead: false },
        { label: "待接收（0）", value: 1, isRead: false },
        { label: "运输中（0）", value: 2, isRead: false },
        { label: "已到位（0）", value: 3, isRead: false },
        { label: "已取消（0）", value: 4, isRead: false }
      ]
    };
  },
  created() {
    if (this.activeResource) {
      this.active = this.activeResource;
    }
    this.getTaskType({
      status: "1,2,4,5",
      type: "4",
      issueId: this.userInfo.id
    });
  },
  methods: {
    ...mapMutations(["setActiveResource"]),
    changeTab(data) {
      this.setActiveResource(data);
    },
    setReadStatusFun(data) {
      this.getTaskType({
        status: "1,2,4,5",
        type: "4",
        issueId: this.userInfo.id
      });
    },
    onClickLeft() {
      history.go(-1);
    },
    getTaskType(data) {
      api.getTaskType(data).then(res => {
        if (res) {
          let total = 0;
          let msgTotal = 0;
          this.tabList = [
            { label: "全部（0）", value: 0, isRead: false },
            { label: "待接收", isRead: false, value: 1 },
            { label: "运输中", isRead: false, value: 2 },
            { label: "已到位", isRead: false, value: 3 },
            { label: "已取消", isRead: false, value: 4 }
          ];
          for (let key in res) {
            if (res[key]) {
              total = total + res[key].count;
              msgTotal = msgTotal + res[key].msgCount;
            }
          }
          this.tabList[0].label = "全部（" + total + "）";
          this.tabList[0].isRead = msgTotal > 0 ? true : false;
          for (let i = 1; i < this.tabList.length; i++) {
            if (i == 3) {
              if (res[5]) {
                this.tabList[i].label =
                  this.tabList[i].label + "（" + res[5].count + "）";
                this.tabList[i].isRead = res[5].msgCount > 0 ? true : false;
              } else {
                this.tabList[i].label = "已到位（0）";
                this.tabList[i].isRead = false;
              }
              continue;
            }
            if (res[i]) {
              this.tabList[i].label =
                this.tabList[i].label + "（" + res[i].count + "）";
              this.tabList[i].isRead = res[i].msgCount > 0 ? true : false;
            } else {
              this.tabList[i].isRead = false;
              this.tabList[i].label = this.tabList[i].label + "（0）";
            }
          }
        }
        console.log(this.tabList);
      });
    }
  }
};
</script>

<style lang="scss">
.resource {
  div {
    box-sizing: border-box;
  }
  font-family: PingFangSC-Regular, PingFang SC;
  .rescuecon {
    .rescuebody {
      background: #f7f8fa;
      height: calc(100vh - 102px);
      overflow: auto;
      padding-bottom: 24px;
    }
    .noread {
      position: absolute;
      right: 18px;
      top: 12px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: #dc0b0b;
    }
  }
  .van-tab {
    color: #969799;
  }
  .van-tabs {
    .van-tabs__wrap {
      border-bottom: none;
    }
    .van-tab--active {
      color: #323233;
      font-weight: 500;
      font-size: 14px;
    }
  }
  .van-tabs__nav--line {
    padding-bottom: 0;
    .van-tabs__wrap {
      height: 44px;
    }
  }
  .van-tabs__line {
    bottom: 0px;
    height: 3px !important;
    width: 50px;
  }
}
</style>
