import axios from "@/utils/axios";

export default {
  getTaskType(params) {
    return axios.get("/accident/statistics/mobile/task/type", { params });
  },
  getTaskList(params) {
    return axios.get("/accident/list/mobile/task", { params });
  },
  //更新任务状态
  updateTaskTrace(params) {
    return axios.post("/accident/update/task/trace", params);
  },
  //事故类型字典
  getShelterTypes(params) {
    return axios.get(`/accident/dict/types`, { params });
  },
  //获取预案详情
  getPlanDetail(id) {
    return axios.get(`/plan/plan/detail/${id}`);
  }
};
